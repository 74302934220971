import { VdChartsLabelSet } from './../vd-charts-labelset';
import './vd-piechart.scss';
import { VdChartsDataSet } from 'components/vd-charts/vd-charts-dataset';
import { VdChartsSeries } from 'components/vd-charts/vd-charts-series';
import { IOptions } from 'components/vd-charts/vd-charts/options/options';
import { PieOptions } from 'components/vd-charts/vd-charts/options/pie-options';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { VdChartsLegendSet } from 'components/vd-charts/vd-charts-legendset';
import SelectionData from 'models/selection-data';
import IAggregatedKpis from 'models/aggregated-kpis';
import { EventAggregator } from 'aurelia-event-aggregator';
import { NumberFormatValueConverter } from 'resources/value-converters/number-format';

@autoinject
export class VdPiechart {

    @bindable
    public hasError: boolean;

    @bindable
    public kpis: IAggregatedKpis;

    public dataset = new VdChartsDataSet();
    public legends = new VdChartsLegendSet();
    public labels = new VdChartsLabelSet();
    private options: IOptions;
    private dataLabelsFormatter: any;

    constructor(private i18n: I18N, private eventAggregator: EventAggregator, private numberFormatValueConverter: NumberFormatValueConverter) {
        this.options = PieOptions.default();

        this.dataLabelsFormatter = function () {
            return `${this.point.name}<br><strong>${numberFormatValueConverter.toView(this.point.value)} kWh<strong>`;
        };
    }

    public async attached() {
        //this.prepareGraph();
        this.eventAggregator.subscribe('data-updated', (data) => this.dataLoaded(data));

        this.options.plotOptions.pie.dataLabels = {
            enabled: true,
            formatter: this.dataLabelsFormatter
        };
    }

    private dataLoaded(data: SelectionData) {
        this.kpis = data.kpis;
        this.prepareGraph();
    }

    private prepareGraph() {
        this.dataset.clearSeries();
        this.dataset.clearYAxis();

        let pieData = new Array();

        this.addData(pieData, 'Bensin', '#8ecae6', this.kpis ? this.kpis.fuelConsumptionGasoline : 0);
        this.addData(pieData, 'Elektrisitet', '#219ebc', this.kpis ? this.kpis.fuelConsumptionElectric : 0);
        this.addData(pieData, 'Diesel', '#023047', this.kpis ? this.kpis.fuelConsumptionDiesel : 0);
        this.addData(pieData, 'Hydrogen', '#ffb703', this.kpis ? this.kpis.fuelConsumptionHydrogen : 0);
        this.addData(pieData, 'Gass', '#8fca48', this.kpis ? this.kpis.fuelConsumptionGas : 0);

        this.dataset.addSeries(new VdChartsSeries()
            .setName('Andel')
            .setType('doughnut')
            .setData(pieData));

        this.legends.positionTop = false;
        this.legends.autoSortLegends = false;
    }

    private addData(pieData: any[], name: string, color: string, value: number) {
        if (value === 0 || value === null || value === undefined || this.kpis === null || this.kpis === undefined) { return; }

        pieData.push({
            name: name,
            y: value / this.kpis.fuelConsumptionTotal,
            color: color,
            value: value
        });
    }
}
