import { autoinject, bindable } from 'aurelia-framework';
import AirDatepicker from 'air-datepicker';
import './vd-date-picker.scss';
import { addDays, differenceInDays, eachDayOfInterval, endOfWeek, isMonday, isSameWeek, isSunday, startOfWeek, subDays } from 'date-fns';
import { I18N } from 'aurelia-i18n';
import { log } from 'services/logger/log';

@autoinject
export class VdDatePicker {
    @bindable
    public from: Date;
    @bindable
    public to: Date;
    @bindable
    public max: Date;
    @bindable
    public label: string;

    public active: boolean = false;

    private datepicker: AirDatepicker<any>;

    constructor(private translator: I18N) { }

    public async attached() {
        window.addEventListener('click', (event) => {
            if (this.active && !this.datepicker.$datepicker.contains(event.target as Node)) {
                this.active = false;
            }
        }, true);

        let lastFocusedDate: Date = null;
        this.datepicker = new AirDatepicker('#datepicker', {
            range: true,
            selectedDates: [this.from, this.to],
            maxDate: this.max,
            multipleDatesSeparator: ' - ',
            locale: {
                days: [
                    this.translator.tr('weekdays.sunday'),
                    this.translator.tr('weekdays.monday'),
                    this.translator.tr('weekdays.tuesday'),
                    this.translator.tr('weekdays.wednesday'),
                    this.translator.tr('weekdays.thursday'),
                    this.translator.tr('weekdays.friday'),
                    this.translator.tr('weekdays.saturday'),
                ],
                daysShort: [
                    this.translator.tr('weekdaysShort.sun'),
                    this.translator.tr('weekdaysShort.mon'),
                    this.translator.tr('weekdaysShort.tue'),
                    this.translator.tr('weekdaysShort.wed'),
                    this.translator.tr('weekdaysShort.thu'),
                    this.translator.tr('weekdaysShort.fri'),
                    this.translator.tr('weekdaysShort.sat'),
                ],
                daysMin: [
                    this.translator.tr('weekdaysShort.sun').slice(0, 2),
                    this.translator.tr('weekdaysShort.mon').slice(0, 2),
                    this.translator.tr('weekdaysShort.tue').slice(0, 2),
                    this.translator.tr('weekdaysShort.wed').slice(0, 2),
                    this.translator.tr('weekdaysShort.thu').slice(0, 2),
                    this.translator.tr('weekdaysShort.fri').slice(0, 2),
                    this.translator.tr('weekdaysShort.sat').slice(0, 2),
                ],
                months: [
                    this.translator.tr('months.january'),
                    this.translator.tr('months.february'),
                    this.translator.tr('months.march'),
                    this.translator.tr('months.april'),
                    this.translator.tr('months.may'),
                    this.translator.tr('months.june'),
                    this.translator.tr('months.july'),
                    this.translator.tr('months.august'),
                    this.translator.tr('months.september'),
                    this.translator.tr('months.october'),
                    this.translator.tr('months.november'),
                    this.translator.tr('months.december'),
                ],
                monthsShort: [
                    this.translator.tr('monthsShort.jan'),
                    this.translator.tr('monthsShort.feb'),
                    this.translator.tr('monthsShort.mar'),
                    this.translator.tr('monthsShort.apr'),
                    this.translator.tr('monthsShort.may'),
                    this.translator.tr('monthsShort.jun'),
                    this.translator.tr('monthsShort.jul'),
                    this.translator.tr('monthsShort.aug'),
                    this.translator.tr('monthsShort.sep'),
                    this.translator.tr('monthsShort.oct'),
                    this.translator.tr('monthsShort.nov'),
                    this.translator.tr('monthsShort.dec'),
                ],
                today: this.translator.tr('calendar.sameDay'),
                clear: 'clear',
                firstDay: 1,
            },
            onFocus: ({ date, datepicker }) => {
                if (!isSameWeek(date, lastFocusedDate)) {
                    this.setFocusWeek(date);
                }
                lastFocusedDate = date;
            },
            onSelect: ({ date, formattedDate, datepicker }) => {
                if (datepicker.selectedDates.length === 1) {
                    const startDate = startOfWeek(datepicker.selectedDates[0], { weekStartsOn: 1 });
                    if (differenceInDays(datepicker.selectedDates[0], startDate) !== 0) {
                        datepicker.unselectDate(datepicker.selectedDates[0]);
                        datepicker.selectDate(startDate);
                    }
                }

                if (datepicker.selectedDates.length === 2) {
                    const endDate = endOfWeek(datepicker.selectedDates[1], { weekStartsOn: 1 });
                    if (differenceInDays(datepicker.selectedDates[1], endDate) !== 0) {
                        datepicker.unselectDate(datepicker.selectedDates[1]);
                        datepicker.selectDate(endDate);
                        this.clearFocusWeek();
                        setTimeout(() => {
                            this.active = false;
                        }, 150);
                    }

                    this.from = datepicker.selectedDates[0];
                    this.to = datepicker.selectedDates[1];
                }
            }
        });
    }

    private clearFocusWeek() {
        const datepickerDaysEl = document.getElementsByClassName('air-datepicker-body--cells -days-')[0];
        for (let i = 0; i < datepickerDaysEl.childElementCount; i++) {
            const day = datepickerDaysEl.children[i];
            day.classList.remove('-focus-week-');
        }
    }

    private setFocusWeek(date: Date) {
        const datepickerDaysEl = document.getElementsByClassName('air-datepicker-body--cells -days-')[0];
        const startDate = startOfWeek(date, { weekStartsOn: 1 });

        for (let i = 0; i < datepickerDaysEl.childElementCount; i++) {
            const day = datepickerDaysEl.children[i];
            const dayDate = parseInt(day.getAttribute('data-date'));
            const monthDate = parseInt(day.getAttribute('data-month'));
            const yearDate = parseInt(day.getAttribute('data-year'));
            const date = new Date(yearDate, monthDate, dayDate);

            if (isSameWeek(date, startDate, { weekStartsOn: 1 })) {
                day.classList.add('-focus-week-');
            } else {
                day.classList.remove('-focus-week-');
            }
        }
    }

    public openPicker() {
        this.active = !this.active;
    }
}
