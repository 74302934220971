import { VdChartsTypes } from './vd-charts-types';

export class VdChartsSeries {
    public name: string = '';
    public data: Array<any> = new Array();
    public max: number;
    public min: number;
    public yaxis: string = 'default';
    public type: string = VdChartsTypes.Spline;
    public color: string;
    public className: string;
    public customFormatter: Function;

    /** Sets the inner diameter of an pie chart, set i.e. 65% to make it a doughnut. */
    public innerSize: string = null;

    constructor() {

    }

    public setName(name: string): VdChartsSeries {
        this.name = name;
        return this;
    }

    public setData(data: Array<any>): VdChartsSeries {
        this.data = data;
        return this;
    }

    public usingYAxis(id: string): VdChartsSeries {
        this.yaxis = id;
        return this;
    }

    public setType(type: string): VdChartsSeries {
        if (type === 'doughnut') {
            this.type = 'pie';
            this.innerSize = '65%';
        }
        else {

            this.type = type;
        }
        return this;
    }

    public setColor(color: string): VdChartsSeries {
        this.color = color;
        return this;
    }

    public setClass(className: string): VdChartsSeries {
        this.className = className;
        return this;
    }

    public setFormatter(formatter: Function) {
        this.customFormatter = formatter;
        return this;
    }
}