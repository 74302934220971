import { Aurelia } from 'aurelia-framework';
import { BaseConfig } from 'aurelia-authentication';
import { PLATFORM } from 'aurelia-pal';
import { AppSettings } from 'config/appsettings';

export const authConfig = {
  baseUrl: AppSettings.endpoints.auth,
  configureEndpoints: ['auth', 'api'],
  loginUrl: 'connect/token',
  httpInterceptor: true,
  authHeader: 'Authorization',
  authTokenType: 'Bearer',
  accessTokenProp: 'access_token',
  useRefreshToken: false,
  loginRedirect: '/',
  logoutRedirect: '/'
};

export const configureAuth = (aurelia: Aurelia) => {
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-authentication'), (baseConfig: BaseConfig) => {
    baseConfig.configure(authConfig);
  });
};
