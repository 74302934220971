import { LogManager } from 'aurelia-framework';
import * as environment from '~/../../config/environment.json';

export interface IEndpoints {
    api: string;
    auth: string;
    fms: string;
}

export class AppSettings {
    public static readonly environment: string = environment.environment;
    public static readonly isLocalDevevelopment: boolean =
        environment.environment == 'local';
    public static readonly isDevevelopment: boolean =
        environment.environment == 'development';
    public static readonly isStaging: boolean =
        environment.environment == 'staging';
    public static readonly isProduction: boolean =
        environment.environment == 'production';
    public static readonly appLogLevel: number =
        LogManager.logLevel[environment.logging.appLogLevel];
    public static readonly appInsightsLogLevel: number =
        LogManager.logLevel[environment.logging.appInsightsLogLevel];

    public static readonly endpoints: IEndpoints = {
        api: environment.endpoints.api,
        auth: environment.endpoints.auth,
        fms: environment.endpoints.fms,
    };

    public static readonly applicationInsightsKey: string =
        environment.applicationInsightsKey;
}

//Output some settings
/* eslint-disable */
console.info('Current environment: ', AppSettings.environment);
console.info('Current app log level: ', environment.logging.appLogLevel);
console.info(
    'Current app insights log level: ',
    environment.logging.appInsightsLogLevel
);
/* eslint-enable */
