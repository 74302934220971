import { MapAggregationCounters } from 'models/map';
import { log } from 'services/logger/log';

export function generateColorRange(
    steps: number,
    from: [number, number, number],
    to: [number, number, number]
): [number, number, number][] {
    const colors = [];

    for (let i = 0; i < steps; i++) {
        colors.push([
            from[0] + (to[0] - from[0]) * (i / steps),
            from[1] + (to[1] - from[1]) * (i / steps),
            from[2] + (to[2] - from[2]) * (i / steps),
        ]);
    }

    return colors;
}

export function generateColor(
    t: number,
    from: [number, number, number],
    to: [number, number, number]
): [number, number, number] {
    return [
        from[0] + (to[0] - from[0]) * t,
        from[1] + (to[1] - from[1]) * t,
        from[2] + (to[2] - from[2]) * t,
    ];
}

export function chargerStationIcon() {
    var icon = new DOMParser().parseFromString(
        `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" fill="#D9D9D9" stroke="black"/>
    <path d="M6 11.1111L7.11111 9.33333H6.44444V8L5.33333 9.77778H6V11.1111ZM4.88889 7.11111H7.55556V4.88889H4.88889V7.11111ZM4.88889 11.1111H7.55556V8H4.88889V11.1111ZM4 12V4.88889C4 4.64444 4.08704 4.43519 4.26111 4.26111C4.43519 4.08704 4.64444 4 4.88889 4H7.55556C7.8 4 8.00926 4.08704 8.18333 4.26111C8.35741 4.43519 8.44444 4.64444 8.44444 4.88889V8H9C9.21481 8 9.39815 8.07593 9.55 8.22778C9.70185 8.37963 9.77778 8.56296 9.77778 8.77778V10.8333C9.77778 10.9593 9.82963 11.0741 9.93333 11.1778C10.037 11.2815 10.1519 11.3333 10.2778 11.3333C10.4111 11.3333 10.5278 11.2815 10.6278 11.1778C10.7278 11.0741 10.7778 10.9593 10.7778 10.8333V6.66667H10.6667C10.5407 6.66667 10.4352 6.62407 10.35 6.53889C10.2648 6.4537 10.2222 6.34815 10.2222 6.22222V5.33333H10.4444V4.66667H10.8889V5.33333H11.3333V4.66667H11.7778V5.33333H12V6.22222C12 6.34815 11.9574 6.4537 11.8722 6.53889C11.787 6.62407 11.6815 6.66667 11.5556 6.66667H11.4444V10.8333C11.4444 11.1444 11.3315 11.4167 11.1056 11.65C10.8796 11.8833 10.6037 12 10.2778 12C9.95926 12 9.68519 11.8833 9.45556 11.65C9.22593 11.4167 9.11111 11.1444 9.11111 10.8333V8.77778C9.11111 8.74074 9.10185 8.71296 9.08333 8.69444C9.06481 8.67593 9.03704 8.66667 9 8.66667H8.44444V12H4Z" fill="black"/>
    </svg>
    `,
        'image/svg+xml'
    ).documentElement;

    icon.style.transform = 'scale(2.0)';
    return icon;
}

export function countersTooltip(counters: MapAggregationCounters): string {
    let result =
        '<div style="display: grid; grid-template-columns: 1fr 1fr;">';
    result += '<p></p><p style="text-align: end;"><b>Antall</b></p>';

    if (counters.unique > 0) {
        result += `<p>Unike eiendeler</p><p style="text-align: end;">${counters.unique}</p>`;
    }

    result += '<p><b>Eiendelstype</b></p><p></p>';
    if (counters.vehicle > 0) {
        result += `<p>Kjøretøy</p><p style="text-align: end;">${counters.vehicle}</p>`;
    }
    if (counters.machine > 0) {
        result += `<p>Maskiner</p><p style="text-align: end;">${counters.machine}</p>`;
    }
    if (counters.truck > 0) {
        result += `<p>Lastebil</p><p style="text-align: end;">${counters.truck}</p>`;
    }
    if (counters.car > 0) {
        result += `<p>Personbil</p><p style="text-align: end;">${counters.car}</p>`;
    }
    if (counters.van > 0) {
        result += `<p>Varebil</p><p style="text-align: end;">${counters.van}</p>`;
    }

    result += '<p><b>Drivkilde</b></p><p></p>';
    if (counters.gasoline > 0) {
        result += `<p>Bensin</p><p style="text-align: end;">${counters.gasoline}</p>`;
    }
    if (counters.diesel > 0) {
        result += `<p>Diesel</p><p style="text-align: end;">${counters.diesel}</p>`;
    }
    if (counters.electric > 0) {
        result += `<p>Elektrisk</p><p style="text-align: end;">${counters.electric}</p>`;
    }
    if (counters.gas > 0) {
        result += `<p>Gass</p><p style="text-align: end;">${counters.gas}</p>`;
    }
    if (counters.hydrogen > 0) {
        result += `<p>Hydrogen</p><p style="text-align: end;">${counters.hydrogen}</p>`;
    }
    if (counters.hybrid > 0) {
        result += `<p>Hybrid</p><p style="text-align: end;">${counters.hybrid}</p>`;
    }
    if (counters.other > 0) {
        result += `<p>Andre</p><p style="text-align: end;">${counters.other}</p>`;
    }

    result += '<p><b>Vektklasse</b></p><p></p>';
    if (counters.lowWeightClass > 0) {
        result += `<p>< 3.5t</p><p style="text-align: end;">${counters.lowWeightClass}</p>`;
    }
    if (counters.mediumWeightClass > 0) {
        result += `<p>3.5t - 7.5t</p><p style="text-align: end;">${counters.mediumWeightClass}</p>`;
    }
    if (counters.highWeightClass > 0) {
        result += `<p>> 7.5t</p><p style="text-align: end;">${counters.highWeightClass}</p>`;
    }
    result += '</div>';

    return result;
}

export type DataFilter = {
    category: string;
    active: boolean;
    range: [min: number, max: number];
    selected: [min: number, max: number];
};

export function createDataFilter(category: string, range: [number, number]): DataFilter {
    return {
        category: category,
        range: range,
        selected: range,
        active: true,
    };
}

export type PositionDataFilter = {
    unique: DataFilter;
    total: DataFilter;
    truck: DataFilter;
    car: DataFilter;
    van: DataFilter;
    vehicle: DataFilter;
    machine: DataFilter;
    gasoline: DataFilter;
    diesel: DataFilter;
    electric: DataFilter;
    gas: DataFilter;
    hydrogen: DataFilter;
    hybrid: DataFilter;
    other: DataFilter;
    lowWeightClass: DataFilter;
    mediumWeightClass: DataFilter;
    highWeightClass: DataFilter;
};

export function defaultPositionDataFilter(): PositionDataFilter {
    return {
        unique: createDataFilter('assetType', [0, 1]),
        total: createDataFilter('assetType', [0, 1]),
        truck: createDataFilter('assetType', [0, 1]),
        car: createDataFilter('assetType', [0, 1]),
        van: createDataFilter('assetType', [0, 1]),
        vehicle: createDataFilter('assetType', [0, 1]),
        machine: createDataFilter('assetType', [0, 1]),
        gasoline: createDataFilter('fuelType', [0, 1]),
        diesel: createDataFilter('fuelType', [0, 1]),
        electric: createDataFilter('fuelType', [0, 1]),
        gas: createDataFilter('fuelType', [0, 1]),
        hydrogen: createDataFilter('fuelType', [0, 1]),
        hybrid: createDataFilter('fuelType', [0, 1]),
        other: createDataFilter('fuelType', [0, 1]),
        lowWeightClass: createDataFilter('weightClass', [0, 1]),
        mediumWeightClass: createDataFilter('weightClass', [0, 1]),
        highWeightClass: createDataFilter('weightClass', [0, 1]),
    };
}

export function updatePositionDataFilterRanges(positionDataFilter: PositionDataFilter, counters: MapAggregationCounters[]) {
    const filterRanges: { [key: string]: [number, number] } = {
        unique: [0, 0],
        total: [0, 0],
        truck: [0, 0],
        car: [0, 0],
        van: [0, 0],
        vehicle: [0, 0],
        machine: [0, 0],
        gasoline: [0, 0],
        diesel: [0, 0],
        electric: [0, 0],
        gas: [0, 0],
        hydrogen: [0, 0],
        hybrid: [0, 0],
        other: [0, 0],
        lowWeightClass: [0, 0],
        mediumWeightClass: [0, 0],
        highWeightClass: [0, 0],
    };

    for (const counter of counters) {
        for (const key of Object.keys(counter)) {
            filterRanges[key][0] = Math.min(
                filterRanges[key][0],
                counter[key]
            );
            filterRanges[key][1] = Math.max(
                filterRanges[key][1],
                counter[key]
            );
        }
    }

    for (const key of Object.keys(filterRanges)) {
        if (filterRanges[key][0] === 1 << 30 || filterRanges[key][0] === filterRanges[key][1]) {
            filterRanges[key] = [0, 1];
        }

        positionDataFilter[key] = <DataFilter>{
            ...positionDataFilter[key],
            active: filterRanges[key][0] !== 0 && filterRanges[key][1] !== 1,
            range: filterRanges[key],
            selected: [
                clamp(positionDataFilter[key].selected[0], filterRanges[key][0], filterRanges[key][1]),
                clamp(positionDataFilter[key].selected[1], filterRanges[key][0], filterRanges[key][1]),
            ],
        };
    }
}

function clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(value, max));
}

export function categorizePositionDataFilter(positionDataFilter: PositionDataFilter) {
    const result: { category: string, items: (keyof PositionDataFilter)[] }[] = [];
    if (!positionDataFilter) {
        return result;
    }

    let key: keyof PositionDataFilter;
    for (key in positionDataFilter) {
        const filter = positionDataFilter[key];
        const category = filter.category;
        if (!result.find(r => r.category === category)) {
            result.push({ category: category, items: <(keyof PositionDataFilter)[]>[] });
        }

        result.find(r => r.category === category).items.push(key);
    }

    return result;
}

export function distinct<T>(array: T[]): T[] {
    return [...new Set(array)];
}

export function distinctBy<T>(array: T[], key: keyof T): T[] {
    return array.filter((value, index, self) => self.findIndex(v => v[key] === value[key]) === index);
}

export function distinctByFn<T>(array: T[], pred: (val: T) => string): T[] {
    return array.filter((value, index, self) => self.findIndex(v => pred(v) === pred(value)) === index);
}
