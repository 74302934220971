import { observable } from 'aurelia-binding';
import { autoinject } from 'aurelia-dependency-injection';
import { BindingSignaler } from 'aurelia-templating-resources';
import { bindable } from 'aurelia-templating';
import './vd-slider-range.scss';

@autoinject
export class VdSliderRange {
    @bindable
    public range: [min: number, max: number] = [0, 1];
    @bindable
    public step: number = 1;

    @bindable
    public startValue: number = 0;
    @bindable
    public endValue: number = 1;

    @observable
    public start: number = 0;
    @observable
    public end: number = 1;

    public min: number = 0;
    public max: number = 1;

    @bindable
    public changed: Function;

    @bindable
    public mode: 'none' | 'some' | 'range' = 'range';
    @observable public noneSelected: boolean = false;
    @observable public someSelected: boolean = false;
    @observable public rangeSelected: boolean = true;

    // slider from aurelia mdc somehow cant handle changing min/max without breakout
    // because of a requirement that min has to be less than max
    // the entire thing crashes if this happens and there is no way to actually force
    // them to be updated in the correct order
    public updating: boolean = false;

    public isAttached: boolean = false;

    constructor(private bindingSignaler: BindingSignaler) { }

    public attached() {
        setTimeout(() => {
            this.isAttached = true;
        }, 0);
    }

    private startChanged(newValue: number, oldValue: number) {
        if (newValue === oldValue) {
            return;
        }

        this.modeChanged();
    }

    private endChanged(newValue: number, oldValue: number) {
        if (newValue === oldValue) {
            return;
        }

        this.modeChanged();
    }

    private rangeChanged() {
        this.updating = true;

        setTimeout(() => {
            this.min = this.range[0];
            this.max = this.range[1];
            this.start = Math.max(this.min, Math.min(this.start, this.max));
            this.end = Math.max(this.min, Math.min(this.end, this.max));
            this.updating = false;
        });
    }

    private startValueChanged(newValue: number) {
        if (this.startValue !== this.start) {
            setTimeout(() => {
                this.start = newValue;
            });
        }
    }

    private endValueChanged(newValue: number) {
        if (newValue !== this.end) {
            setTimeout(() => {
                this.end = newValue;
            });
        }
    }

    private modeChanged() {
        setTimeout(() => {
            if (this.mode === 'none') {
                if (this.changed) {
                    this.changed({ start: 0, end: 0 });
                }
            }
            if (this.mode === 'some') {
                if (this.changed) {
                    this.changed({ start: 1, end: this.max });
                }
            }
            if (this.mode === 'range') {
                if (this.changed) {
                    this.changed({ start: this.start, end: this.end });
                }
            }
        }, 0);
    }

    private noneSelectedChanged() {
        if (this.noneSelected) {
            this.mode = 'none';
        }
    }

    private someSelectedChanged() {
        if (this.someSelected) {
            this.mode = 'some';
        }
    }

    private rangeSelectedChanged() {
        if (this.rangeSelected) {
            this.mode = 'range';
        }
    }
}
