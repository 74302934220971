import { Options, IOptions } from './options';

export class PieOptions extends Options {
    public static default(): IOptions {
        let options = Options.default();
        // Override tooltip as the default one does not fit a pie.
        options.tooltip = {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        };
        options.responsive = null;
        // options.legend = {
        //     enabled: true,
        //     itemStyle: {
        //         fontFamily: 'Poppins, Tahoma, Geneva, Verdana, sans-serif'
        //     }
        // };
        options.plotOptions = {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: (window.innerWidth > 1095),
                    format: '{point.name}:<br><b>{point.value}</b>',
                    connectorColor: 'silver'
                },
                showInLegend: true
            }
        };

        return options;
    }
}
