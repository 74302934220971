import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject
export class NumberFormatValueConverter {
    constructor(private i18n: I18N) {

    }

    public toView(value: any, min: number = 0) {
        if (typeof value === 'undefined' || value === '' || value === null || value === '-' || isNaN(value)) {
            return min;
        }

        return this.i18n.nf(undefined, 'nb-NO').format(parseFloat(value));
    }
}