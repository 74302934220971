import { Navbar } from './../../components/navbar/navbar';
import { MainHeader } from './../../components/main-header/main-header';
import './home.scss';
import { autoinject } from 'aurelia-framework';
import { VdChartsSeries } from 'components/vd-charts/vd-charts-series';
import { VdChartsDataSet } from 'components/vd-charts/vd-charts-dataset';
import { VdChartsLabelSet } from 'components/vd-charts/vd-charts-labelset';
import { VdChartsYAxis } from 'components/vd-charts/vd-charts-yaxis';
import { IOptions, Options } from 'components/vd-charts/vd-charts/options/options';
import { ApiService } from 'services/api/api-service';
import { I18N } from 'aurelia-i18n';
import { AvailableTypes } from 'models/available-types';
import SelectionData from 'models/selection-data';
import { PeriodAlternatives } from 'models/period-alternatives';
import { EventAggregator } from 'aurelia-event-aggregator';
import { VehicleCategories } from 'models/vehicle-categories';
import { NumberFormatValueConverter } from 'resources/value-converters/number-format';

@autoinject
export class Home {
    public navbar: Navbar;
    public header: MainHeader;
    public dataSet = new VdChartsDataSet();
    public labels: VdChartsLabelSet;
    public options: IOptions = Options.default();
    public showFilter: boolean;
    public showEnergySources: boolean;
    public data: SelectionData;

    public filterSelectedCategory: VehicleCategories;
    public filterSelectedPeriod: PeriodAlternatives;

    public availableTypeEnum = AvailableTypes;
    public vehicleCategories = VehicleCategories;
    public periodAlternatives = PeriodAlternatives;

    public trendTooltipFormatter: any;

    public kWhToPetrolRatio: number = 0.10989; // 1 kWh * ratio = liter
    public kWhToDieselRatio: number = 0.09901; // 1 kWh * ratio = liter
    public kWhToGasRatio: number = 0.074074; // 1 kwh * ratio = kg
    public kWhToHydrogenRatio: number = 0.02994; // 1 kWh * ratio = kg

    constructor(private apiService: ApiService, private i18n: I18N, private eventAggregator: EventAggregator, private numberFormat: NumberFormatValueConverter) {

    }

    public async attached() {
        this.data = new SelectionData(this.apiService, this.eventAggregator);
        await this.data.loadData();

        this.options.yAxis = {
            labels: {
                staggerLines: 1
            }
        };

        this.filterSelectedCategory = VehicleCategories.All;
        this.filterSelectedPeriod = PeriodAlternatives.Last30Days;

        this.eventAggregator.subscribe('data-updated', () => this.prepareGraph());
        this.prepareGraph();
    }

    private get getPeriodSelectionText() {
        switch (this.data.period) {
            case PeriodAlternatives.Last30Days:
                return 'siste 30 dager';
            case PeriodAlternatives.Yesterday:
                return 'i går';
            case PeriodAlternatives.LastYear:
                return 'forrige år';
            case PeriodAlternatives.YearToDate:
                return 'år til dato';
            default:
                return '';
        }
    }

    private setFilterSelectedCategory(category: VehicleCategories) {
        this.filterSelectedCategory = category;
    }

    private setFilterSelectedPeriod(period: PeriodAlternatives) {
        this.filterSelectedPeriod = period;
    }

    private applyFilter() {
        this.data.updateFilterSelection(this.filterSelectedCategory, this.filterSelectedPeriod);
        this.showFilter = false;
    }

    private toggleFilter(e: MouseEvent) {
        this.showFilter = !this.showFilter;
        e?.stopPropagation();
    }
    private hideFilter(e: MouseEvent) {
        this.showFilter = false;
        e?.stopPropagation();
    }

    // test 
    private toggleEnergySources(e: MouseEvent) {
        this.showEnergySources = !this.showEnergySources;
        e?.stopPropagation();
    }
    private hideEnergySources(e: MouseEvent) {
        this.showEnergySources = false;
        e?.stopPropagation();
    }

    // TODO: Fix clickthrough
    private ignoreFilterClick(e: MouseEvent) {
        e?.stopPropagation();
    }

    private prepareGraph() {
        // Change this if other than last 13 months
        this.labels = this.getLabelSetForLastThirteenMonths();

        this.dataSet.clearSeries();
        this.dataSet.clearYAxis();

        var yAxis = new VdChartsYAxis('normalized', 'Gjennomsnitt');
        yAxis.setFormatter(function () {
            if (this.value === undefined) {
                return 'test';
            }

            return `${parseInt(this.value?.toFixed(2))}%`;
        });

        this.dataSet.addYAxis(yAxis);
        //   .setMax(100));


        // We need one series for each graph
        if (this.data.selectedType === AvailableTypes.Vehicle) {
            this.dataSet.addSeries(new VdChartsSeries()
                .setName('Distanse')
                .setColor('#003f5c')
                .setData(this.data.normalizedTrends.distance)
                .setFormatter(customSeriesFormatter));
        } else {
            this.dataSet.addSeries(new VdChartsSeries()
                .setName('Timer')
                .setColor('#003f5c')
                .setData(this.data.normalizedTrends.engineRunTimeHours)
                .setFormatter(customSeriesFormatter));
        }

        this.dataSet.addSeries(new VdChartsSeries()
            .setName('Totalforbruk')
            .setColor('#8fca48')
            .setData(this.data.normalizedTrends.fuelConsumptionTotal)
            .setFormatter(customSeriesFormatter));

        if (this.data.selectedType === AvailableTypes.Vehicle) {
            this.dataSet.addSeries(new VdChartsSeries()
                .setName('Snittforbruk per mil')
                .setColor('#f1c400')
                .setData(this.data.normalizedTrends.averageFuelConsumptionPer10Km)
                .setFormatter(customSeriesFormatter));
        } else {
            this.dataSet.addSeries(new VdChartsSeries()
                .setName('Snittforbruk per time')
                .setColor('#f1c400')
                .setData(this.data.normalizedTrends.averageFuelConsumptionPerHour)
                .setFormatter(customSeriesFormatter));
        }

        this.dataSet.addSeries(new VdChartsSeries()
            .setName('CO\u2082  totalt')
            .setColor('#3697b5')
            .setData(this.data.normalizedTrends.co2EmissionTotal)
            .setFormatter(customSeriesFormatter));

        var data = this.data;
        var nf = this.numberFormat;
        this.trendTooltipFormatter = function () {
            var actual = '';
            switch (this.series.name) {
                case 'Distanse':
                    actual = `${nf.toView(data.trends.distance[this.point.index])} km`;
                    break;
                case 'Timer':
                    actual = `${nf.toView(data.trends.engineRunTimeHours[this.point.index])} timer`;
                    break;
                case 'Totalforbruk':
                    actual = `${nf.toView(data.trends.fuelConsumptionTotal[this.point.index])} kwh`;
                    break;
                case 'Snittforbruk per mil':
                    actual = `${nf.toView(data.trends.averageFuelConsumptionPer10Km[this.point.index])} kwh/mil`;
                    break;
                case 'Snittforbruk per time':
                    actual = `${nf.toView(data.trends.averageFuelConsumptionPerHour[this.point.index])} kwh/time`;
                    break;
                case 'CO\u2082  totalt':
                    actual = `${nf.toView(data.trends.co2EmissionTotal[this.point.index])} tonn`;
                    break;
                default:
                    break;
            }
            return `
                ${this.point.category}<br>
                <strong>${actual}</strong><br>
                ${Math.round(this.point.y)}%
            `;
        };
        this.options.tooltip.formatter = this.trendTooltipFormatter;
    }

    private get getSubCategoryName() {
        if (this.data === undefined || this.data.selectedType === undefined) {
            return 'kjøretøy';
        }
        switch (this.data.selectedType) {
            case AvailableTypes.All:
                return 'kjøretøy og maskiner';
            case AvailableTypes.Machine:
                return 'maskiner';
            case AvailableTypes.Vehicle:
                switch (this.data.selectedVehicleCategory) {
                    case VehicleCategories.All:
                        return 'kjøretøy';
                    case VehicleCategories.Cars:
                        return 'personbiler';
                    case VehicleCategories.Vans:
                        return 'varebiler';
                    case VehicleCategories.Trucks:
                        return 'lastebiler';
                    default: '';
                }
            default: '';
        }
    }

    private get getMainCategoryCount() {
        if (this.data === undefined || this.data.selectedType === undefined) {
            return 'kjøretøy';
        }
        if (this.data.assetCounts === undefined) {
            return '-';
        }
        switch (this.data.selectedType) {
            case AvailableTypes.All:
                return this.data.assetCounts.machinesCount + this.data.assetCounts.vehiclesCount;
            case AvailableTypes.Vehicle:
                return this.data.assetCounts.vehiclesCount;
            case AvailableTypes.Machine:
                return this.data.assetCounts.machinesCount;
            default: return 0;
        }
    }


    public getLabelSetForLastThirteenMonths(): VdChartsLabelSet {
        var series: Array<string> = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

        var xaxis = [];
        for (var i = 13; i > 0; i--) {
            var d = this.getDate(i);
            xaxis.push(series[d.getMonth()].slice(0, 3) + ' ' + d.getFullYear());
        }

        return new VdChartsLabelSet().setLabels(xaxis);
    }

    private getDate(months: number) {
        var d = new Date();
        d.setMonth(d.getMonth() - months);

        return d;
    }
}

function customSeriesFormatter() {
    var a = this.value;
    return 'test';
}
