import './navbar.scss';

export class Navbar {
    public isShowing: boolean;
    constructor() {}

    public async attached() {}

    public toggle() {
        this.isShowing = !this.isShowing;
    }

    public goToSection(id: string) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        this.toggle();
    }
}
