import { Appender } from 'aurelia-logging';
import { AppInsights } from 'applicationinsights-js';
import { AppSettings } from 'config/appsettings';
import { LogManager } from 'aurelia-framework';


export class AppLogAppender implements Appender {

    public debug(logger: { id: string }, ...rest: any[]): void {
        if (AppSettings.appInsightsLogLevel >= LogManager.logLevel.debug) {
            let name = typeof (rest[0]) == 'string' ? typeof (rest[0]) : 'Unknown';
            AppInsights.trackEvent(name, Object.assign({ level: 'debug' }, rest[1]));
            // eslint-disable-next-line no-console
            console.debug(`DEBUG [AI] [${logger.id}]`, ...rest);
        }
        else {
            // eslint-disable-next-line no-console
            console.debug(`DEBUG [${logger.id}]`, ...rest);
        }
    }

    public info(logger: { id: string }, ...rest: any[]): void {
        if (AppSettings.appInsightsLogLevel >= LogManager.logLevel.info) {
            let name = typeof (rest[0]) == 'string' ? typeof (rest[0]) : 'Unknown';
            AppInsights.trackEvent(name, Object.assign({ level: 'info' }, rest[1]));
            // eslint-disable-next-line no-console
            console.info(`INFO [AI] [${logger.id}]`, ...rest);
        }
        else {
            // eslint-disable-next-line no-console
            console.info(`INFO [${logger.id}]`, ...rest);
        }
    }

    public warn(logger: { id: string }, ...rest: any[]): void {
        if (AppSettings.appInsightsLogLevel >= LogManager.logLevel.warn) {
            let name = typeof (rest[0]) == 'string' ? typeof (rest[0]) : 'Unknown';
            AppInsights.trackEvent(name, Object.assign({ level: 'warn' }, rest[1]));
            // eslint-disable-next-line no-console
            console.warn(`WARN [AI] [${logger.id}]`, ...rest);
        }
        else {
            // eslint-disable-next-line no-console
            console.warn(`WARN [${logger.id}]`, ...rest);
        }
    }

    public error(logger: { id: string }, ...rest: any[]): void {
        if (AppSettings.appInsightsLogLevel >= LogManager.logLevel.error) {
            let name = typeof (rest[0]) == 'string' ? typeof (rest[0]) : 'Unknown';
            AppInsights.trackEvent(name, Object.assign({ level: 'error' }, rest[1]));
            // eslint-disable-next-line no-console
            console.error(`ERROR [AI] [${logger.id}]`, ...rest);
        }
        else {
            // eslint-disable-next-line no-console
            console.error(`ERROR [${logger.id}]`, ...rest);
        }
    }
}