import { VdChartsLabelSet } from './vd-charts-labelset';

export class VdChartsMonths {
    private static getDate(months: number) {
        var d = new Date();
        d.setMonth(d.getMonth() - months);

        return d;
    }

    public static getLabelSetForLastThirteenMonths(): VdChartsLabelSet {
        var series: Array<string> = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

        var xaxis = [];
        for (var i = 13; i > 0; i--) {
            var d = this.getDate(i);
            xaxis.push(series[d.getMonth()].slice(0, 3) + ' ' + d.getFullYear());
        }

        return new VdChartsLabelSet().setLabels(xaxis);
    }

    public getLabelSetForLastThirteenMonths(): VdChartsLabelSet {
        return VdChartsMonths.getLabelSetForLastThirteenMonths();
    }

    public longMonthFormatter(e) {
        return e.value;
    }

    public shortMonthFormatter(e) {

        if (e.axis.series.findIndex(x => x.options.type === 'histogram' || x.options.type === 'column') != -1) {
            return e.value;
        }
        if (typeof e.value === 'string' || e.value instanceof String) {
            return e.value.slice(0, 3);
        }
        return e.value;
    }
}
