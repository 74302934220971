import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import { GoogleMapsOverlay as DeckOverlay } from '@deck.gl/google-maps';
import {
    GeoJsonLayer,
    IconLayer,
    IconLayerProps,
    ScatterplotLayer,
    ScatterplotLayerProps,
} from '@deck.gl/layers';
import {
    GPUGridLayer,
    GPUGridLayerProps,
    HeatmapLayer,
    HeatmapLayerProps,
    HexagonLayer,
    HexagonLayerProps,
} from '@deck.gl/aggregation-layers';
import { H3HexagonLayer, H3HexagonLayerProps } from '@deck.gl/geo-layers';
import { Layer, LayerProps } from '@deck.gl/core';
import { log } from 'services/logger/log';

export type Data = {
    position: [longitude: number, latitude: number];
};

export type LayerConfig<TData extends Data> = {
    getTooltip: (data: TData | any) => string;
    minZoom: number;
    maxZoom: number;
};

export class MapLayerData<TData extends Data> {
    private data: TData[] = [];
    private filtered: TData[] = [];

    public getData() {
        return this.filtered;
    }

    public setData(data: TData[]) {
        this.data = data;
        this.filtered = this.data;
    }

    public applyFilter(filter: Filter<TData>) {
        this.filtered = this.data.filter((d) => {
            const data = filter.getFilterValue(d);
            for (let i = 0; i < data.length; i++) {
                if (
                    data[i] < filter.filterRange[i][0] ||
                    data[i] > filter.filterRange[i][1]
                ) {
                    return false;
                }
            }

            return true;
        });
    }

    public applyDataFilter(filter: (data: TData) => boolean) {
        this.filtered = this.data.filter(filter);
    }
}

export class MapLayers<TData extends Data> {
    private map: google.maps.Map;
    private data: MapLayerData<TData>;
    private layers: Map<string, MapLayer<TData>> = new Map();
    private filter: Filter<TData>;

    constructor(map: google.maps.Map, data: MapLayerData<TData>) {
        this.map = map;
        this.data = data;
    }

    public update() {
        this.layers.forEach((layer) => layer.update());
    }

    public setFilter(filter: Filter<TData>) {
        this.filter = filter;
    }

    public updateFilter(ranges: [number, number][]) {
        if (!this.filter) {
            log.error('No filter set');
            return;
        }

        this.filter.filterRange = ranges;
        this.data.applyFilter(this.filter);
    }

    public show() {
        this.layers.forEach((layer) => layer.show());
    }

    public hide() {
        this.layers.forEach((layer) => layer.hide());
    }

    public addScatterplotLayer(
        id: string,
        layerConfig: Partial<ScatterplotLayerProps<TData>>,
        config: LayerConfig<TData>
    ) {
        let layer = new ScatterplotLayer<TData>({
            id: id,
            ...layerConfig,
        });

        this.layers.set(
            id,
            new DeckMapLayer(id, this.map, this.data, layer, config)
        );
    }

    public addHexagonLayer(
        id: string,
        hexConfig: Partial<Required<HexagonLayerProps<TData>>> & any,
        config: LayerConfig<TData>
    ) {
        let layer = new HexagonLayer<TData>({
            id: id,
            ...hexConfig,
        });

        this.layers.set(
            id,
            new DeckMapLayer(id, this.map, this.data, layer, config)
        );
    }

    public addH3HexagonLayer(
        id: string,
        hexConfig: Partial<Required<H3HexagonLayerProps<TData>>> & any,
        config: LayerConfig<TData>
    ) {
        let layer = new H3HexagonLayer<TData>({
            id: id,
            ...hexConfig,
        });

        this.layers.set(
            id,
            new DeckMapLayer(id, this.map, this.data, layer, config)
        );
    }

    public addGPUGridLayer(
        id: string,
        gridConfig: Partial<Required<GPUGridLayerProps<TData>>> & any,
        config: LayerConfig<TData>
    ) {
        let layer = new GPUGridLayer<TData>({
            id: id,
            ...gridConfig,
        });

        this.layers.set(
            id,
            new DeckMapLayer(id, this.map, this.data, layer, config)
        );
    }

    public addHeatmapLayer(
        id: string,
        heatmapConfig: Partial<Required<HeatmapLayerProps<TData>>> & any,
        config: LayerConfig<TData>
    ) {
        let layer = new HeatmapLayer<TData>({
            id: id,
            ...heatmapConfig,
        });

        this.layers.set(
            id,
            new DeckMapLayer(id, this.map, this.data, layer, config)
        );
    }

    public addIconLayer(
        id: string,
        iconConfig: Partial<IconLayerProps<TData>>,
        config: LayerConfig<TData>
    ) {
        let layer = new IconLayer<TData>({
            id: id,
            ...iconConfig,
        });

        this.layers.set(
            id,
            new DeckMapLayer(id, this.map, this.data, layer, config)
        );
    }

    public addClusteredMarkerLayer(
        id: string,
        markerConfig: MarkerConfig<TData>,
        config: LayerConfig<TData>
    ) {
        const layer = new ClusterMapLayer<TData>(
            id,
            this.map,
            this.data,
            config,
            markerConfig
        );

        this.layers.set(id, layer);
    }

    public getLayer(id: string): MapLayer<TData> {
        return this.layers.get(id);
    }
}

export abstract class MapLayer<TData extends Data> {
    protected id: string;
    protected data: MapLayerData<TData>;
    protected googleMap: google.maps.Map;

    constructor(
        id: string,
        googleMap: google.maps.Map,
        data: MapLayerData<TData>
    ) {
        this.id = id;
        this.data = data;
        this.googleMap = googleMap;
    }

    public abstract update(): void;
    public abstract show();
    public abstract hide();
}

export class DeckMapLayer<TData extends Data> extends MapLayer<TData> {
    private overlay: DeckOverlay;
    private layer: Layer<{}>;
    private visible: boolean;
    private forceHidden: boolean;

    constructor(
        id: string,
        googleMap: google.maps.Map,
        data: MapLayerData<TData>,
        layer: Layer<{}>,
        private layerConfig: LayerConfig<TData>
    ) {
        super(id, googleMap, data);

        this.layer = layer;
        this.overlay = new DeckOverlay({
            layers: [layer],
            layerFilter: (layer) => this.visible,
            getTooltip: ({ object }) => {
                if (object) {
                    const html = layerConfig.getTooltip(object);
                    return {
                        html: html,
                        style: {
                            backgroundColor: 'white',
                            color: 'black',
                        },
                    };
                }
                return null;
            },
        });
        this.overlay.setMap(googleMap);

        this.checkVisible();
        googleMap.addListener('zoom_changed', this.checkVisible.bind(this));
    }

    public update() {
        if (!this.visible) {
            return;
        }

        this.layer = this.layer.clone({ data: this.data.getData() });
        this.overlay.setProps({ layers: [this.layer] });
    }

    public updateProps(props: Partial<Required<{}>>) {
        this.layer = this.layer.clone(props);
        this.overlay.setProps({ layers: [this.layer] });
    }

    public show() {
        this.forceHidden = false;
        this.checkVisible();
    }

    public hide() {
        this.forceHidden = true;
        this.checkVisible();
    }

    private checkVisible() {
        const zoom = Math.floor(this.googleMap.getZoom());
        const visible =
            zoom >= this.layerConfig.minZoom &&
            zoom <= this.layerConfig.maxZoom &&
            !this.forceHidden;

        if (visible != this.visible) {
            this.visible = visible;
            this.overlay.setProps({
                layers: [
                    (this.layer = this.layer.clone({
                        visible: this.visible,
                    })),
                ],
            });
        }
    }
}

export type MarkerConfig<TData extends Data> = {
    getId: (data: TData | Cluster) => string;
    getTooltip: (data: TData | Cluster) => string;
    getContent: (data: TData | Cluster) => Node;
};

export class ClusterMapLayer<TData extends Data> extends MapLayer<TData> {
    private clusterer: MarkerClusterer;
    private markers: {
        uniqueId: string;
        marker: google.maps.marker.AdvancedMarkerElement;
    }[] = [];

    constructor(
        id: string,
        googleMap: google.maps.Map,
        data: MapLayerData<TData>,
        private layerConfig: LayerConfig<TData>,
        private markerConfig: MarkerConfig<TData>
    ) {
        super(id, googleMap, data);
        this.clusterer = new MarkerClusterer({
            map: this.googleMap,
            markers: [],
        });

        this.clusterer.addListener('clusteringend', () => {
            this.markers.filter((e) => e.marker.map);
        });
    }

    public update(): void {
        for (const data of this.data.getData()) {
            const id = this.markerConfig.getId(data);
            const marker = this.markers.find((m) => m.uniqueId == id);

            if (marker) {
                marker.marker.position = {
                    lat: data.position[1],
                    lng: data.position[0],
                };
            } else {
                let content = this.markerConfig.getContent(data);
                const mapMarker = new google.maps.marker.AdvancedMarkerElement({
                    position: {
                        lat: data.position[1],
                        lng: data.position[0],
                    },
                    map: this.googleMap,
                    content: content,
                    title: this.markerConfig.getTooltip(data),
                    gmpClickable: true,
                });
                mapMarker.addListener('click', ({ domEvent, latLng }) => {
                    const { target } = domEvent;
                });

                const marker = { uniqueId: id, marker: mapMarker };
                this.markers.push(marker);
            }
        }

        this.clusterer.clearMarkers(true);
        this.clusterer.addMarkers(
            this.markers.map((e) => e.marker),
            false
        );
    }

    public show() { }
    public hide() { }
}

export type Filter<TData> = {
    getFilterValue: (d: TData) => number[];
    filterRange: [number, number][];
};

export function createFilter<TData>(
    filterRanges: [number, number][],
    filterSelector: (d: TData) => number[]
): Filter<TData> {
    return {
        getFilterValue: filterSelector,
        filterRange: filterRanges,
    };
}
