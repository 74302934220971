import { autoinject } from 'aurelia-dependency-injection';
import { GoogleMapsApi } from 'services/google-maps/google-maps-api';
import { DeckMapLayer, MapLayerData, MapLayers, createFilter } from './map-layer';
import { log } from 'services/logger/log';
import { NobilAttr, NobilAttrs, NobilModel, loadNobilData } from './nobil';
import $ from 'jquery';
import { computedFrom, observable } from 'aurelia-framework';
import { MapAggregationCounters } from 'models/map';
import { ApiService } from 'services/api/api-service';
import { endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { DataFilter, PositionDataFilter, categorizePositionDataFilter, countersTooltip, createDataFilter, defaultPositionDataFilter, distinct, distinctBy, distinctByFn, generateColor, generateColorRange, updatePositionDataFilterRanges } from './map-utils';
import { Abortable } from 'lib/abortable';
import './vd-map.scss';
import { BindingSignaler } from 'aurelia-templating-resources';
import { cellToLatLng, h3IndexToSplitLong, latLngToCell } from 'h3-js';
import { I18N } from 'aurelia-i18n';

const INITIAL_VIEW_STATE = {
    latitude: 59.24794819375307,
    longitude: 10.259915969375003,
    zoom: 15,
};

type PositionData = {
    position: [longitude: number, latitude: number];
    color: [r: number, g: number, b: number];
    radius: number;
    counters: MapAggregationCounters;
};

type ChargingStationData = {
    name: string;
    positionHash: string;
    position: [longitude: number, latitude: number];
    ids: number[];
    chargingPoints: number;
    connections: ChargingStationConnection[];
};

type ChargingStationConnection = {
    connectorType: NobilAttr;
    vehicleType: NobilAttr;
    chargeMode: NobilAttr;
    energyCarrier: NobilAttr;
};

type ChargingStationFilter = {
    connectorType: {
        allSelected: boolean;
        options: (NobilAttr & { selected: boolean })[];
    };
    vehicleType: {
        allSelected: boolean;
        options: (NobilAttr & { selected: boolean })[];
    };
    chargeMode: {
        allSelected: boolean;
        options: (NobilAttr & { selected: boolean })[];
    };
    energyCarrier: {
        allSelected: boolean;
        options: (NobilAttr & { selected: boolean })[];
    };
};

@autoinject
export class VdMap {
    public filterOpen: boolean = true;

    private googleMap: google.maps.Map;
    private prevBounds: google.maps.LatLngBounds;
    private prevZoom: number;

    private isLoadingPositionData: boolean;
    private isLoadingChargerStationData: boolean;
    @computedFrom('isLoadingPositionData', 'isLoadingChargerStationData')
    public get isLoading() {
        return this.isLoadingPositionData || this.isLoadingChargerStationData;
    }

    private positionDataFetch: Abortable = new Abortable();
    private positionData: MapLayerData<PositionData> = new MapLayerData();
    private positionLayer: MapLayers<PositionData>;
    public positionDataFilter: PositionDataFilter = defaultPositionDataFilter();
    public positionDataFilters: { category: string, items: (keyof PositionDataFilter)[] }[] = categorizePositionDataFilter(this.positionDataFilter);

    // jquery xhr request object
    private chargerStationDataFetch: any;
    private chargerStationData: MapLayerData<ChargingStationData> =
        new MapLayerData();
    private chargerStationLayer: MapLayers<ChargingStationData>;
    private chargerStationCache: Map<string, ChargingStationData> = new Map();
    public anyChargingStationFilterActive: boolean;
    public chargerStationFilterOptions: ChargingStationFilter = {
        connectorType: { options: [], allSelected: true },
        vehicleType: { options: [], allSelected: true },
        chargeMode: { options: [], allSelected: true },
        energyCarrier: { options: [], allSelected: true },
    };
    public get chargerStationFilterOptionsKeys() {
        return Object.keys(this.chargerStationFilterOptions);
    }

    @observable
    public fromDate: Date = subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1);
    @observable
    public toDate: Date = subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1);
    public maxDate = subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1);

    @observable
    public showPositionsLayer: boolean = true;
    @observable
    public showChargerStationsLayer: boolean = true;

    constructor(
        private googleMapsApi: GoogleMapsApi,
        private apiService: ApiService,
        private bindingSignaler: BindingSignaler,
        private translator: I18N
    ) { }

    public async attached() {
        await this.googleMapsApi.load();

        const mapOpts: google.maps.MapOptions = {
            mapId: 'f840a9d786b69ed',
            isFractionalZoomEnabled: false,
            center: {
                lat: INITIAL_VIEW_STATE.latitude,
                lng: INITIAL_VIEW_STATE.longitude,
            },
            zoom: INITIAL_VIEW_STATE.zoom,
            gestureHandling: 'cooperative',
            controlSize: 20,
            fullscreenControl: false,
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            clickableIcons: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        };

        this.googleMap = this.googleMapsApi.map(
            document.getElementById('map-container'),
            mapOpts
        );

        this.positionLayer = new MapLayers(this.googleMap, this.positionData);
        this.positionLayer.setFilter(
            createFilter(
                Object.values(this.positionDataFilter).map(f => f.selected),
                (d) => {
                    return Object.values(d.counters);
                }
            )
        );


        this.positionLayer.addScatterplotLayer(
            'points',
            {
                getPosition: (d) => d.position,
                getFillColor: (d) => d.color,
                getRadius: (d) => d.radius,
                radiusMinPixels: 4,
                radiusMaxPixels: 24,
                pickable: true,
                visible: true,
                autoHighlight: true,
            },
            {
                getTooltip: (d) => countersTooltip(d.counters),
                minZoom: 13,
                maxZoom: 100,
            }
        );

        function aggregatedLayerTooltip(obj): string {
            const acc = (<any[]>obj.points).reduce(
                (acc, p) => {
                    for (const key of Object.keys(p.source.counters)) {
                        acc[key] += p.source.counters[key];
                    }
                    return acc;
                },
                <MapAggregationCounters>{
                    total: 0,
                    unique: 0,
                    vehicle: 0,
                    machine: 0,
                    truck: 0,
                    car: 0,
                    van: 0,
                    gasoline: 0,
                    diesel: 0,
                    electric: 0,
                    gas: 0,
                    hydrogen: 0,
                    hybrid: 0,
                    other: 0,
                    lowWeightClass: 0,
                    mediumWeightClass: 0,
                    highWeightClass: 0,
                }
            );
            return countersTooltip(acc);
        }

        this.positionLayer.addHexagonLayer(
            'hex',
            {
                extruded: false,
                getPosition: (d: PositionData) => d.position,
                getColorWeight: (d: PositionData) => Math.min(d.counters.total, 30),
                colorRange: generateColorRange(
                    32,
                    [0, 100, 100],
                    [0xf1, 0xc4, 0x00]
                ),
                opacity: 0.5,
                radius: 400,
                pickable: true,
                visible: true,
                autoHighlight: true,
                onClick: (info) => {
                    const bounds = new google.maps.LatLngBounds({
                        lat: info.coordinate[1],
                        lng: info.coordinate[0],
                    });
                    this.googleMap.fitBounds(bounds);
                    this.googleMap.setZoom(this.prevZoom + 1);
                    return true;
                },
            },
            {
                getTooltip: aggregatedLayerTooltip,
                minZoom: 7,
                maxZoom: 12,
            }
        );

        this.positionLayer.addHeatmapLayer(
            'heatmap',
            {
                getPosition: (d) => d.position,
                getWeight: (d) => d.counters.total,
                colorRange: generateColorRange(
                    32,
                    [0, 100, 100],
                    [0xf1, 0xc4, 0x00]
                ),
                radiusPixels: 50,
                intensity: 1,
                visible: true,
                pickable: false,
                opacity: 0.5,
            },
            {
                getTooltip: aggregatedLayerTooltip,
                minZoom: 0,
                maxZoom: 6,
            }
        );

        this.chargerStationLayer = new MapLayers(
            this.googleMap,
            this.chargerStationData
        );

        this.chargerStationLayer.addIconLayer(
            'charging-stations',
            {
                getPosition: (d) => d.position,
                getIcon: () => ({
                    url: '/images/icons/charging_station.png',
                    width: 64,
                    height: 64,
                }),
                getSize: 24,
                pickable: true,
                visible: true,
                autoHighlight: true,
            },
            {
                getTooltip: (d: ChargingStationData) => {
                    let result = `Energistasjon <p><b>${d.name}</b></p>`;

                    const connectorTypes = distinct(d.connections.filter(c => c.connectorType).map(c => this.translator.tr('nobil.attr.' + c.connectorType.attrtypeid + '.' + c.connectorType.attrvalid))).join(', ');
                    if (connectorTypes?.length > 0) {
                        result += `<p>Ladetyper: ${connectorTypes}</p>`;
                    }
                    const chargeModes = distinct(d.connections.filter(c => c.chargeMode).map(c => this.translator.tr('nobil.attr.' + c.chargeMode.attrtypeid + '.' + c.chargeMode.attrvalid))).join(', ');
                    if (chargeModes?.length > 0) {
                        result += `<p>Lademoduser: ${chargeModes}</p>`;
                    }

                    result += `
                    <p>Energitype: ${distinct(d.connections.filter(c => c.energyCarrier).map(c => this.translator.tr('nobil.attr.' + c.energyCarrier.attrtypeid + '.' + c.energyCarrier.attrvalid))).join(', ')}</p>
                    <p>Kjøretøystype: ${distinct(d.connections.filter(c => c.vehicleType).map(c => this.translator.tr('nobil.attr.' + c.vehicleType.attrtypeid + '.' + c.vehicleType.attrvalid))).join(', ')}</p>
                    `;

                    result += '<br/><i>Data hentet fra NOBIL.no</i>';
                    return result;
                },
                minZoom: 13,
                maxZoom: 100,
            }
        );

        this.prevBounds = this.googleMap.getBounds();
        this.prevZoom = this.googleMap.getZoom();
        this.googleMap.addListener('idle', () => {
            if (this.prevBounds !== this.googleMap.getBounds()) {
                this.prevBounds = this.googleMap.getBounds();
                if (this.prevBounds) {
                    this.loadChargerStationData();
                    this.loadPositionData();
                }
            }

            if (this.prevZoom !== this.googleMap.getZoom()) {
                this.prevZoom = this.googleMap.getZoom();

                const hexLayer = this.positionLayer.getLayer('hex') as DeckMapLayer<PositionData>;
                hexLayer.updateProps({
                    radius: 200 / Math.pow(2, this.prevZoom - 12),
                });
            }
        });
    }

    private async loadPositionData() {
        if (!this.prevBounds) {
            return;
        }

        if (this.positionDataFetch.isActive) {
            this.positionDataFetch.abort();
        }
        this.isLoadingPositionData = true;

        const bounds = {
            swLat: this.prevBounds.getSouthWest().lat(),
            swLng: this.prevBounds.getSouthWest().lng(),
            neLat: this.prevBounds.getNorthEast().lat(),
            neLng: this.prevBounds.getNorthEast().lng(),
        };

        const metersPerPx = 156543.03392 / Math.pow(2, this.googleMap.getZoom());
        const positionDataRaw = await this.apiService.getMapAggregatedPositions(
            Math.floor(metersPerPx * 5), bounds, this.fromDate, this.toDate, this.positionDataFetch.signal
        ).catch((e) => {
            if (e === 'aborted by user') {
                return;
            }
            this.isLoadingPositionData = false;
        });
        if (!positionDataRaw) {
            return;
        }

        this.isLoadingPositionData = false;
        const positionData: PositionData[] = positionDataRaw.features.map(
            (f) =>
                <PositionData>{
                    position: [
                        f.geometry.coordinates[0],
                        f.geometry.coordinates[1],
                    ],
                    color: generateColor(
                        (Math.max(0, Math.min(50, f.properties.counters.total)) / 50),
                        [0, 100, 100],
                        [0xf1, 0xc4, 0x00]
                    ),
                    radius: Math.min(f.properties.counters.unique * 5 + 2, 50),
                    counters: f.properties.counters,
                }
        );

        updatePositionDataFilterRanges(this.positionDataFilter, positionData.map(d => d.counters));

        this.positionData.setData(positionData);
        this.positionLayer.updateFilter(Object.values(this.positionDataFilter).map(f => f.selected));
        this.positionLayer.update();

        this.bindingSignaler.signal('positionDataFilter');
    }

    private async loadChargerStationData() {
        const bounds = this.googleMap.getBounds();
        if (!bounds) {
            return;
        }
        if (this.googleMap.getZoom() < 13) {
            return;
        }

        this.chargerStationDataFetch?.abort();
        this.isLoadingChargerStationData = true;

        const handleResponse = (data: NobilModel) => {
            for (const cs of data.chargerstations) {
                const connections: ChargingStationConnection[] = [];

                for (const conn of Object.values(cs.attr.conn)) {
                    if (conn['20'] && !this.chargerStationFilterOptions.chargeMode.options.some(c => c.attrvalid === conn['20'].attrvalid)) {
                        this.chargerStationFilterOptions.chargeMode.options.push({ ...conn['20'], selected: true });
                    }
                    if (conn['4'] && !this.chargerStationFilterOptions.connectorType.options.some(c => c.attrvalid === conn['4'].attrvalid)) {
                        this.chargerStationFilterOptions.connectorType.options.push({ ...conn['4'], selected: true });
                    }
                    if (conn['26'] && !this.chargerStationFilterOptions.energyCarrier.options.some(c => c.attrvalid === conn['26'].attrvalid)) {
                        this.chargerStationFilterOptions.energyCarrier.options.push({ ...conn['26'], selected: true });
                    }
                    if (conn['17'] && !this.chargerStationFilterOptions.vehicleType.options.some(c => c.attrvalid === conn['17'].attrvalid)) {
                        this.chargerStationFilterOptions.vehicleType.options.push({ ...conn['17'], selected: true });
                    }

                    connections.push({
                        chargeMode: conn['20'],
                        connectorType: conn['4'],
                        energyCarrier: conn['26'],
                        vehicleType: conn['17'],
                    });
                }

                if (this.chargerStationCache.has(cs.csmd.Position)) {
                    const curr = this.chargerStationCache.get(cs.csmd.Position);
                    if (!curr.ids.includes(cs.csmd.id)) {
                        curr.ids.push(cs.csmd.id);
                        curr.chargingPoints += cs.csmd.Available_charging_points;
                        curr.connections.push(...connections);
                    }
                    continue;
                }

                this.chargerStationCache.set(cs.csmd.Position, <
                    ChargingStationData
                    >{
                        positionHash: cs.csmd.Position,
                        name: cs.csmd.name,
                        ids: [cs.csmd.id],
                        chargingPoints: cs.csmd.Available_charging_points,
                        connections: connections,
                        position: cs.csmd.Position.slice(
                            1,
                            cs.csmd.Position.length - 2
                        )
                            .split(',')
                            .map((e) => parseFloat(e))
                            .reverse(),
                    });
            }

            this.chargerStationData.setData(
                Array.from(this.chargerStationCache.values())
            );
            this.filterChargingStations();
        };

        try {
            this.chargerStationDataFetch = loadNobilData(bounds, handleResponse).always(() => {
                this.isLoadingChargerStationData = false;
            });
        } catch (e) { log.error(e); }
    }

    public positionDataFilterChanged(
        min: number,
        max: number,
        key: keyof PositionDataFilter
    ) {
        this.positionDataFilter[key].selected = [Number(min), Number(max)];

        this.positionLayer.updateFilter(Object.values(this.positionDataFilter).map(f => f.selected));
        this.positionLayer.update();
        this.bindingSignaler.signal('positionDataFilter');
    }

    private showPositionsLayerChanged() {
        if (!this.showPositionsLayer) {
            this.positionLayer?.hide();
        } else {
            this.positionLayer?.show();
        }
    }

    private showChargerStationsLayerChanged() {
        if (!this.showChargerStationsLayer) {
            this.chargerStationLayer?.hide();
        } else {
            this.chargerStationLayer?.show();
        }
    }

    private fromDateChanged() {
        this.loadPositionData();
    }

    private toDateChanged() {
        this.loadPositionData();
    }

    public filterChanged(filter: keyof PositionDataFilter): boolean {
        if (!this.positionDataFilter || !(filter in this.positionDataFilter)) {
            return;
        }

        const filterItem = this.positionDataFilter[filter];
        const changed = filterItem.range[0] !== filterItem.selected[0] ||
            filterItem.range[1] !== filterItem.selected[1];
        return changed;
    }

    public filterCategoryChanged(category: string): boolean {
        return this.positionDataFilters.find(f => f.category === category)?.items.some(i => this.filterChanged(i));
    }

    public onChargingStationFilterChanged(attr: keyof ChargingStationFilter, selected: { rowIndex: number }) {
        this.anyChargingStationFilterActive = this.chargerStationFilterOptions.chargeMode.options.some(c => !c.selected) ||
            this.chargerStationFilterOptions.connectorType.options.some(c => !c.selected) ||
            this.chargerStationFilterOptions.energyCarrier.options.some(c => !c.selected) ||
            this.chargerStationFilterOptions.vehicleType.options.some(c => !c.selected);
        this.filterChargingStations();
    }

    public filterChargingStations() {
        this.chargerStationData.applyDataFilter((d) => {
            return this.chargerStationFilterOptions.connectorType.options.some(c => c.selected && d.connections.some(conn => !conn.connectorType || conn.connectorType.attrvalid === c.attrvalid)) &&
                this.chargerStationFilterOptions.vehicleType.options.some(c => c.selected && d.connections.some(conn => !conn.vehicleType || conn.vehicleType.attrvalid === c.attrvalid)) &&
                this.chargerStationFilterOptions.chargeMode.options.some(c => c.selected && d.connections.some(conn => !conn.chargeMode || conn.chargeMode.attrvalid === c.attrvalid)) &&
                this.chargerStationFilterOptions.energyCarrier.options.some(c => c.selected && d.connections.some(conn => !conn.energyCarrier || conn.energyCarrier.attrvalid === c.attrvalid));
        });
        this.chargerStationLayer.update();
        this.bindingSignaler.signal('chargerStationFilterOptions');
    }
}
