export class VdChartsYAxis {
    public id: string;
    public title: string;
    public max: number;
    public min: number;
    public opposite: boolean = false;
    public formatter: Function;

    constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
    }

    public setMax(max: number): VdChartsYAxis {
        this.max = max;
        return this;
    }

    public setMin(min: number): VdChartsYAxis {
        this.min = min;
        return this;
    }

    public setOpposite(opposite: boolean): VdChartsYAxis {
        this.opposite = opposite;
        return this;
    }

    public setFormatter(func: Function): VdChartsYAxis {
        this.formatter = func;
        return this;
    }
}