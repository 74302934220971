import moment from 'moment';

export class DateFormatValueConverter {
    public toView(value: any, ...args: string[]) {
        if (!value) {
            return 'ugyldig dato';
        }
        var utc = args.indexOf('utc') > -1;
        var seconds = args.indexOf('seconds') > -1;
        var showTime = seconds || args.indexOf('time') > -1;
        var noDate = args.indexOf('nodate') > -1;
        var noYear = args.indexOf('noyear') > -1;

        let format = '';
        if (!noDate) {
            format += 'DD. MMM' + (noYear ? '' : ' YYYY') + ' ';
        }
        if (showTime) {
            format += 'HH:mm' + (seconds ? ':ss' : '');
        }

        let date = utc ? moment.utc(value) : moment(value);
        if (date.year() == 1) {
            return '-';
        }
        return date.format(format);

        // return new DateFormatValueConverter().toView(value, utc, showTime, seconds);
    }
}
