export class Abortable {
    private abortController: AbortController;

    public get signal(): { signal: AbortSignal } {
        this.abortController ??= new AbortController();
        return {
            signal: this.abortController.signal
        };
    }

    public get isActive(): boolean {
        return this.abortController !== undefined && this.abortController !== null;
    }

    public abort() {
        try {
            this.abortController?.abort('aborted by user');
            this.abortController = null;
        } catch (e) { }
    }
}
