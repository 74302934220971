import './vd-charts.scss';
import { bindable, BindingEngine, inject } from 'aurelia-framework';
import * as Highcharts from 'highcharts';

import Histogram from 'highcharts/modules/histogram-bellcurve';
Histogram(Highcharts);

import { Options } from './options/options';
import { VdChartsLabelSet } from '../vd-charts-labelset';
import { VdChartsDataSet } from '../vd-charts-dataset';
import { VdChartsYAxis } from '../vd-charts-yaxis';
import { VdChartsSeries } from '../vd-charts-series';
import { VdChartsTypes } from '../vd-charts-types';
import { VdChartsLegendSet } from '../vd-charts-legendset';
import { tools } from '../../../lib/tools';

@inject(BindingEngine)
export class VdCharts {
    @bindable
    public header: string;

    @bindable
    public labels: VdChartsLabelSet;

    @bindable
    public data: VdChartsDataSet;

    @bindable
    public id: string = 'container';

    @bindable
    public legends: VdChartsLegendSet;

    @bindable
    public options: Options;

    public charts: any = null;
    public isAttached: boolean = false;

    public bindingEngine: BindingEngine;
    public subscription: any;
    public palette: any = [
        '#185177',
        '#B9CAD6',
        '#969696',
        '#2ead36',
        '#6a0000',
        '#0544d3',
        '#6b0392',
        '#f05b4f',
        '#dda458',
        '#eacf7d',
        '#86797d',
        '#b2c326',
        '#6188e2',
        '#a748ca'];

    public paletteIndex = 0;

    public isSet(prop: any) {
        return typeof (prop) !== 'undefined' && prop !== null;
    }

    public getNextColor(): string {
        var color = this.palette[this.paletteIndex];
        this.paletteIndex++;

        return color;
    }

    public draw(data: VdChartsDataSet, labels: VdChartsLabelSet) {
        var chartOptions = this.options ? this.options : Options.default();

        if (typeof labels !== 'undefined' && labels! !== null) {
            var labelOptions = {
                xAxis: {
                    tickmarkPlacement: 'between',
                    categories: labels.labels
                }
            };

            chartOptions = tools.extend(chartOptions, labelOptions);
        }

        if (typeof data !== 'undefined' && data !== null) {
            var dataOptions = {
                series: [],
                yAxis: []
            };

            if (data.yAxis.length == 0) {
                data.addYAxis(new VdChartsYAxis('myOtherAxis', ''));
            }

            // Y-axis

            if (data.yAxis.length === 2) {
                data.yAxis[1].opposite = true;
            }

            data.yAxis.forEach((x: VdChartsYAxis) => {
                var axis = {
                    allowDecimals: false,
                    id: x.id,
                    min: null,
                    max: null,
                    opposite: false,
                    gridLineWidth: 0,
                    alignTicks: false,
                    //offset: 20,
                    title: {
                        text: null
                    },
                    labels: {
                        formatter: null
                    }
                };

                if (this.isSet(x.max)) {
                    axis.max = x.max;
                }

                if (this.isSet(x.min)) {
                    axis.min = x.min;
                }

                if (this.isSet(x.title)) {
                    axis.title.text = x.title;
                }

                if (this.isSet(x.opposite)) {
                    axis.opposite = x.opposite;
                }

                if (this.isSet(x.formatter)) {
                    axis.labels.formatter = x.formatter;
                }

                dataOptions.yAxis.push(axis);
            });

            // Series
            this.paletteIndex = 0;
            data.series.forEach((x: VdChartsSeries) => {

                var yAxisNo = dataOptions.yAxis.findIndex((y: VdChartsYAxis) => y.id === x.yaxis);
                if (yAxisNo < 0) {
                    yAxisNo = 0;
                }

                dataOptions.series.push({
                    name: x.name,
                    data: x.data,
                    //customFormatter: data.yAxis[yAxisNo].formatter,
                    lineWidth: 3,
                    type: this.isSet(x.type) ? x.type : VdChartsTypes.Spline,
                    yAxis: yAxisNo,
                    color: this.isSet(x.color) ? x.color : this.getNextColor(),
                    innerSize: x.innerSize
                });
            });

            // General
            chartOptions = tools.extend(chartOptions, dataOptions);
        }

        if (this.isAttached) {
            this.charts = Highcharts.chart(this.id, chartOptions);
        }
    }

    constructor(bindingEngine) {
        this.bindingEngine = bindingEngine;

    }


    public detached() {
        this.subscription.dispose();
    }


    public attached() {
        this.subscription = this.bindingEngine
            .propertyObserver(this.data, 'series')
            .subscribe((newValue, oldValue) => {
                this.data.series = newValue;
                this.draw(this.data, this.labels);
            });
        this.isAttached = true;
        this.draw(this.data, this.labels);
    }
}
