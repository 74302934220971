import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';
import { AvailableTypes } from 'models/available-types';
import SelectionData from 'models/selection-data';
import './main-header.scss';

@autoinject
export class MainHeader {

    @bindable
    public data: SelectionData;
    public availableTypeEnum = AvailableTypes;

    constructor(private eventAggregator: EventAggregator) {

    }

    public async attached() {
        this.eventAggregator.subscribe('data-updated', this.dataLoaded);
    }

    private dataLoaded(data: SelectionData) {
        this.data = data;
    }

    public changeSelection(selection: AvailableTypes) {
        this.data.setSelectedType(selection);
    }
}


