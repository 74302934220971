import { autoinject } from 'aurelia-framework';
import { Config, Rest } from 'aurelia-api';
import IAssetCounts from 'models/asset-counts';
import IAggregatedKpis from 'models/aggregated-kpis';
import IAggregatedTrends from 'models/aggregated-trends';
import { AvailableTypes } from 'models/available-types';
import { VehicleCategories } from 'models/vehicle-categories';
import { getQueryString } from 'lib/queryString';
import { log } from 'services/logger/log';
import { AppSettings } from 'config/appsettings';
import moment from 'moment';
import { addMinutes, format, subMinutes } from 'date-fns';

@autoinject
export class ApiService {
    private api: Rest;
    private fmsApi: Rest;

    constructor(private apiConfig: Config) {
        this.api = apiConfig.getEndpoint('api');
        this.api.defaults = {
            headers: {
                'Api-Version': 'v1',
            },
        };

        this.fmsApi = apiConfig.getEndpoint('fms');
    }

    public async getSome(): Promise<string> {
        return this.api.find('/some');
    }

    public async getKpis(
        from: Date,
        to: Date,
        type: AvailableTypes,
        category: VehicleCategories
    ): Promise<IAggregatedKpis> {
        var categoryType = this.getCategoryType(type, category);
        return this.api.find(
            `/aggregated/kpis?from=${encodeURIComponent(
                from.toISOString()
            )}&to=${encodeURIComponent(to.toISOString())}${categoryType === null ? '' : `&categoryType=${categoryType}`
            }`
        );
    }

    public async getTrends(
        from: Date,
        to: Date,
        type: AvailableTypes,
        category: VehicleCategories
    ): Promise<IAggregatedTrends> {
        var categoryType = this.getCategoryType(type, category);
        return this.api.find(
            `/aggregated/trends?from=${encodeURIComponent(
                from.toISOString()
            )}&to=${encodeURIComponent(to.toISOString())}${categoryType === null ? '' : `&categoryType=${categoryType}`
            }`
        );
    }

    public async getAssetCounts(): Promise<IAssetCounts> {
        return this.api.find('/assets/counts');
    }

    private getCategoryType(type: AvailableTypes, category: VehicleCategories) {
        switch (type) {
            case AvailableTypes.All:
                return null;
            case AvailableTypes.Machine:
                return 'Machines';
            case AvailableTypes.Vehicle:
                if (
                    category === null ||
                    category === undefined ||
                    category === VehicleCategories.All
                ) {
                    return 'Vehicles';
                } else {
                    switch (category) {
                        case VehicleCategories.Cars:
                            return 'Car';
                        case VehicleCategories.Vans:
                            return 'Van';
                        case VehicleCategories.Trucks:
                            return 'Truck';
                        default:
                            return 'Unknown';
                    }
                }
            default:
                return 'Unknown';
        }
    }

    public async getMapAggregatedPositions(
        metersForGrouping: number,
        mapBounds: { swLat: number; swLng: number; neLat: number; neLng: number },
        fromDate: Date,
        toDate: Date,
        abortSignal: { signal: AbortSignal }
    ): Promise<any> {
        const params = getQueryString({
            metersForGrouping: metersForGrouping,
            from: subMinutes(fromDate, fromDate.getTimezoneOffset()).toJSON(),
            to: subMinutes(toDate, toDate.getTimezoneOffset()).toJSON(),
            ...mapBounds,
        });

        if (AppSettings.isLocalDevevelopment) {
            return this.fmsApi.find(`/api/externalapi/map/aggregated${params}`, undefined, abortSignal);
        } else {
            return this.api.find(`/map/aggregated${params}`, undefined, abortSignal);
        }
    }
}
