import IAssetCounts from 'models/asset-counts';
import { AvailableTypes } from './available-types';
import IAggregatedKpis from './aggregated-kpis';
import IAggregatedTrends from './aggregated-trends';
import { VehicleCategories } from './vehicle-categories';
import { PeriodAlternatives } from './period-alternatives';
import { ApiService } from 'services/api/api-service';
import { EventAggregator } from 'aurelia-event-aggregator';

export interface INormalizedTrends {
    distance: number[];
    engineRunTimeHours: number[];
    averageFuelConsumptionPerHour: number[];
    averageFuelConsumptionPer10Km: number[];
    co2EmissionTotal: number[];
    fuelConsumptionTotal: number[];
}

export default class SelectionData {
    public selectedType: AvailableTypes;
    public selectedVehicleCategory: VehicleCategories;
    public period: PeriodAlternatives;
    public from: Date;
    public to: Date;
    public assetCounts: IAssetCounts;
    public kpis: IAggregatedKpis;
    public environmentScore: number;
    public trends: IAggregatedTrends;
    public normalizedTrends: INormalizedTrends = {
        distance: [],
        engineRunTimeHours: [],
        averageFuelConsumptionPer10Km: [],
        averageFuelConsumptionPerHour: [],
        co2EmissionTotal: [],
        fuelConsumptionTotal: []
    };

    constructor(private apiService: ApiService, private eventAggregator: EventAggregator) {
        this.selectedType = AvailableTypes.All;
        this.selectedVehicleCategory = VehicleCategories.All;
        this.period = PeriodAlternatives.Last30Days;
        this.setPeriodLast30Days();
    }

    public async loadData() {
        this.selectedType = AvailableTypes.Vehicle;
        this.assetCounts = await this.apiService.getAssetCounts();
        await this.loadKpis();
        await this.loadTrends();
        this.calculateNormalizedTrends();

        this.eventAggregator.publish('data-updated', this);
    }

    private calculateNormalizedTrends() {
        if (this.selectedType === AvailableTypes.Vehicle) {
            this.normalizedTrends.distance = this.calculateNormalized(this.trends.distance);
        } else {
            this.normalizedTrends.engineRunTimeHours = this.calculateNormalized(this.trends.engineRunTimeHours);
        }

        if (this.selectedType === AvailableTypes.Vehicle) {
            this.normalizedTrends.averageFuelConsumptionPer10Km = this.calculateNormalized(this.trends.averageFuelConsumptionPer10Km);
        } else {
            this.normalizedTrends.averageFuelConsumptionPerHour = this.calculateNormalized(this.trends.averageFuelConsumptionPerHour);
        }
        this.normalizedTrends.co2EmissionTotal = this.calculateNormalized(this.trends.co2EmissionTotal);
        this.normalizedTrends.fuelConsumptionTotal = this.calculateNormalized(this.trends.fuelConsumptionTotal);
    }

    private calculateNormalized(values: Array<number>): number[] {
        var valueCount = 0;
        var total = 0;
        for (let i = 0; i < values.length; i++) {
            if (values[i] === undefined || values[i] === null) {
                continue;
            }
            total += values[i];
            valueCount++;
        }
        var avg = total / valueCount;
        var normalized = [];

        for (let i = 0; i < values.length; i++) {
            if (values[i] === undefined || values[i] === null) {
                normalized.push(null);
                continue;
            };
            normalized.push(((values[i] / avg) * 100) - 100);
        }

        return normalized;
    }

    public async updateFilterSelection(category: VehicleCategories, period: PeriodAlternatives) {
        this.period = period;
        this.selectedVehicleCategory = category;
        switch (period) {
            case PeriodAlternatives.Last30Days:
                this.setPeriodLast30Days();
                break;
            case PeriodAlternatives.Yesterday:
                this.setPeriodYesterday();
                break;
            case PeriodAlternatives.LastYear:
                this.setPeriodLastYear();
                break;
            case PeriodAlternatives.YearToDate:
                this.setPeriodYearToDate();
                break;
            default:
                break;
        }

        await this.loadKpis();
        this.eventAggregator.publish('data-updated', this);
    }

    public async setSelectedType(type: AvailableTypes) {
        this.selectedType = type;
        await this.loadKpis();
        await this.loadTrends();

        this.eventAggregator.publish('data-updated', this);
    }

    private async loadTrends() {
        var trendStart = new Date();
        trendStart.setFullYear(trendStart.getFullYear() - 1);
        trendStart.setMonth(trendStart.getMonth() - 1);
        trendStart.setDate(1);
        trendStart.setUTCHours(0, 0, 0, 0);

        var trendEnd = new Date();
        trendEnd.setDate(0);
        trendEnd.setUTCHours(23, 59, 99, 99);

        this.trends = await this.apiService.getTrends(trendStart, trendEnd, this.selectedType, this.selectedVehicleCategory);
        this.calculateNormalizedTrends();
    }

    private async loadKpis() {
        this.kpis = await this.apiService.getKpis(this.from, this.to, this.selectedType, this.selectedVehicleCategory);
        this.environmentScore = Math.round((this.kpis.driverScore + this.kpis.planningScore + this.kpis.idlingScore) / 3);
    }

    private setPeriodLast30Days() {
        this.from = new Date();
        this.to = new Date();

        this.from.setFullYear(this.from.getFullYear(), this.from.getMonth(), this.from.getDate() - 30);
        this.from.setHours(0, 0, 0, 0);
        this.to.setFullYear(this.to.getFullYear(), this.to.getMonth(), this.to.getDate());
        this.to.setHours(0, 0, 0, 0);
    }

    private setPeriodYesterday() {
        this.from = new Date();
        this.to = new Date();

        this.from.setFullYear(this.from.getFullYear(), this.from.getMonth(), this.from.getDate() - 1);
        this.from.setHours(0, 0, 0, 0);
        this.to.setFullYear(this.to.getFullYear(), this.to.getMonth(), this.to.getDate());
        this.to.setHours(0, 0, 0, 0);
    }

    private setPeriodLastYear() {
        this.from = new Date();
        this.to = new Date();

        this.from.setFullYear(this.from.getFullYear() - 1, 0, 1);
        this.from.setHours(0, 0, 0, 0);
        this.to.setFullYear(this.to.getFullYear(), 0, 0);
        this.to.setHours(0, 0, 0, 0);
    }

    private setPeriodYearToDate() {
        this.from = new Date();
        this.to = new Date();

        this.from.setFullYear(this.from.getFullYear(), 0, 1);
        this.from.setHours(0, 0, 0, 0);
        this.to.setFullYear(this.to.getFullYear(), this.to.getMonth(), this.to.getDate());
        this.to.setHours(0, 0, 0, 0);
    }
}
