export class VdChartsLabelSet {
    public labels: Array<string> = new Array<string>();

    public clearLabels() {
        if (this.labels) {
            this.labels.slice(0, 0);
        }
    }

    public setLabels(labels: Array<string>): VdChartsLabelSet {
        this.labels = labels;

        return this;
    }
}