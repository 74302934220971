export function getQueryString(params) {
    var result = Object.keys(params)
        .map((k) => {
            if (params[k] != null) {
                if (Array.isArray(params[k])) {
                    return params[k]
                        .map(
                            (val) =>
                                `${encodeURIComponent(k)}=${encodeURIComponent(
                                    val
                                )}`
                        )
                        .join('&');
                }
                return `${encodeURIComponent(k)}=${encodeURIComponent(
                    params[k]
                )}`;
            } else {
                return null;
            }
        })
        .filter((f) => f != null)
        .join('&');

    if (result) {
        return '?' + result;
    }

    return '';
}
