import { I18N } from 'aurelia-i18n';
import { StringHelper } from './stringHelper';

export class CalendarHelper {

    public static getMonthNames(i18n: I18N, capitalize: boolean = false): Array<string> {
        let monthNames: Array<string> =
            [
                i18n.tr('months.january'),
                i18n.tr('months.february'),
                i18n.tr('months.march'),
                i18n.tr('months.april'),
                i18n.tr('months.may'),
                i18n.tr('months.june'),
                i18n.tr('months.july'),
                i18n.tr('months.august'),
                i18n.tr('months.september'),
                i18n.tr('months.october'),
                i18n.tr('months.november'),
                i18n.tr('months.december')
            ];

        if (capitalize) {
            monthNames = StringHelper.capitalizeArray(monthNames);
        }

        return monthNames;
    }

    public static getMonthNamesShort(i18n: I18N, capitalize: boolean = false): Array<string> {
        let monthNames: Array<string> =
            [
                i18n.tr('monthsShort.jan'),
                i18n.tr('monthsShort.feb'),
                i18n.tr('monthsShort.mar'),
                i18n.tr('monthsShort.apr'),
                i18n.tr('monthsShort.may'),
                i18n.tr('monthsShort.jun'),
                i18n.tr('monthsShort.jul'),
                i18n.tr('monthsShort.aug'),
                i18n.tr('monthsShort.sep'),
                i18n.tr('monthsShort.oct'),
                i18n.tr('monthsShort.nov'),
                i18n.tr('monthsShort.dec')
            ];

        if (capitalize) {
            monthNames = StringHelper.capitalizeArray(monthNames);
        }
        return monthNames;
    }

    public static getWeekNames(i18n: I18N, capitalize: boolean = false): Array<string> {
        let weekNames: Array<string> =
            [
                i18n.tr('weekdays.monday'),
                i18n.tr('weekdays.tuesday'),
                i18n.tr('weekdays.wednesday'),
                i18n.tr('weekdays.thursday'),
                i18n.tr('weekdays.friday'),
                i18n.tr('weekdays.saturday'),
                i18n.tr('weekdays.sunday')
            ];
        if (capitalize) {
            weekNames = StringHelper.capitalizeArray(weekNames);
        }
        return weekNames;
    }

    public static getWeekNamesShort(i18n: I18N, capitalize: boolean = false): Array<string> {
        let weekNames: Array<string> =
            [
                i18n.tr('weekdaysShort.mon'),
                i18n.tr('weekdaysShort.tue'),
                i18n.tr('weekdaysShort.wed'),
                i18n.tr('weekdaysShort.thu'),
                i18n.tr('weekdaysShort.fri'),
                i18n.tr('weekdaysShort.sat'),
                i18n.tr('weekdaysShort.sun')
            ];
        if (capitalize) {
            weekNames = StringHelper.capitalizeArray(weekNames);
        }
        return weekNames;
    }

    public static getCalendarTexts(i18n: I18N): any {
        let texts =
        {
            sameDay: i18n.tr('calendar.sameDay'),
            nextDay: i18n.tr('calendar.nextDay'),
            nextWeek: i18n.tr('calendar.nextWeek'),
            lastDay: i18n.tr('calendar.lastDay'),
            lastWeek: i18n.tr('calendar.lastWeek'),
            sameElse: i18n.tr('calendar.sameElse')
        };

        return texts;
    }

    public static getCalendarTextsRelative(i18n: I18N): any {
        let texts =
        {
            future: i18n.tr('relativeTime.future'),
            past: i18n.tr('relativeTime.past'),
            s: i18n.tr('relativeTime.s'),
            ss: i18n.tr('relativeTime.ss'),
            m: i18n.tr('relativeTime.m'),
            mm: i18n.tr('relativeTime.mm'),
            h: i18n.tr('relativeTime.h'),
            hh: i18n.tr('relativeTime.hh'),
            d: i18n.tr('relativeTime.d'),
            dd: i18n.tr('relativeTime.dd'),
            M: i18n.tr('relativeTime.M'),
            MM: i18n.tr('relativeTime.MM'),
            y: i18n.tr('relativeTime.y'),
            yy: i18n.tr('relativeTime.yy')
        };

        return texts;
    }
}