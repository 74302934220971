export class NumericValueConverter {
  public toView(value: any, min: number = 0) {
    if (typeof value === 'undefined' || value === '' || value === null || value === '-' || isNaN(value)) {
      return min;
    }

    return parseFloat(value);
  }

  public fromView(value: any, min: number = 0) {

    if (typeof value === 'undefined' || value === '' || value === null || value === '-' || isNaN(value)) {
      return min;
    }

    return parseFloat(value);
  }
}
