import { VdChartsSeries } from './vd-charts-series';
import { VdChartsYAxis } from './vd-charts-yaxis';

export class VdChartsDataSet {
    public series: Array<VdChartsSeries> = new Array();
    public yAxis: Array<VdChartsYAxis> = new Array();

    constructor() {

    }

    public addSeries(series: VdChartsSeries): VdChartsDataSet {

        var copy = this.series.slice();
        copy.push(series);
        this.series = copy;

        return this;
    }

    public removeSeries(name: string): VdChartsDataSet {
        var index = this.series.findIndex(x => x.name === name);
        this.series = this.series.slice(index - 1, index);

        return this;
    }

    public clearSeries() {
        this.series = [];
    }

    public addYAxis(axis: VdChartsYAxis): VdChartsDataSet {

        if (this.yAxis.findIndex(x => x.id === axis.id) === -1) {
            this.yAxis.push(axis);
        }

        return this;
    }

    public removeYAxis(id: string): VdChartsDataSet {
        var index = this.yAxis.findIndex(x => x.id == id);
        this.yAxis = this.yAxis.slice(index - 1, index);

        return this;
    }

    public clearYAxis() {
        this.yAxis = [];
    }
}