import { Loader } from '@googlemaps/js-api-loader';
import { inject } from 'aurelia-framework';

const apiVersion = '3.55' as const;

@inject(
    new Loader({
        apiKey: 'AIzaSyAStos0LcB4lmHgvoBRIygQtNcUuhuM0SM',
        version: apiVersion,
        libraries: ['marker', 'geometry'],
    })
)
export class GoogleMapsApi {
    private googleApi: any;

    private loading: Promise<void>;

    constructor(private googleApiLoader: Loader) {
        this.load();
    }

    public get api(): any {
        return this.googleApi;
    }

    public async load() {
        if (this.googleApi) {
            return;
        }

        if (this.loading) {
            await this.loading;
            return;
        }

        this.loading = this.googleApiLoader.load().then((googleApi) => {
            this.googleApi = googleApi;
        });

        await this.loading;
    }

    public map(
        container: Element,
        options: google.maps.MapOptions
    ): google.maps.Map {
        if (!this.googleApi) {
            return null;
        }

        return new this.googleApi.maps.Map(container, options);
    }
}
