import { VdChartsMonths } from '../../vd-charts-months';

export interface IEnabled {
    enabled: boolean;
}

export interface IOptions {
    exporting: boolean;
    legend: any;
    credits: IEnabled;
    colors: Array<string>;
    chart: any;
    title: any;
    subtitle: any;
    tooltip: any;
    labels: any;
    responsive: any;
    plotOptions: any;
    yAxis: any;
}

export class Options {
    public static default(): IOptions {
        return {
            exporting: false,
            legend: {
                enabled: true,
                itemStyle: {
                    fontSize: '14px',
                    fontFamily: 'Poppins, Tahoma, Geneva, Verdana, sans-serif'
                }
            },
            credits: {
                enabled: false
            },
            colors: [
                '#058DC7',
                '#50B432',
                '#ED561B',
                '#DDDF00',
                '#24CBE5',
                '#64E572',
                '#FF9655',
                '#FFF263',
                '#6AF9C4'
            ],
            chart: {
                backgroundColor: 'transparent',
                style: {
                    fontFamily: 'roboto, verdana',
                    height: null,
                    width: null
                }
            },

            title: {
                text: '',
                style: {
                    display: 'none'
                }
            },

            subtitle: {
                text: '',
                style: {
                    display: 'none'
                }
            },
            tooltip: {
                formatter: function () {
                    var xValue = this.x;
                    var yValue = this.y;
                    if (this.series && this.series.options && this.series.options.customFormatter) {
                        yValue = this.series.options.customFormatter({ value: this.y });
                    }

                    return xValue + '<br>' + '<strong>' + yValue + '</strong>';
                }
            },
            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'Roboto, verdana',
                    color: 'rgba(0, 0, 0, 0.54)'
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 600
                    },
                    chartOptions: {
                        xAxis: {
                            labels: {
                                enabled: false
                            }
                        }
                    }
                }, {
                    condition: {
                        minWidth: 600
                    },
                    chartOptions: {
                        xAxis: {
                            labels: {
                                enabled: true,
                                formatter: new VdChartsMonths().shortMonthFormatter
                            }
                        }
                    }
                }, {
                    condition: {
                        minWidth: 1280
                    },
                    chartOptions: {
                        xAxis: {
                            labels: {
                                enabled: true,
                                formatter: new VdChartsMonths().longMonthFormatter
                            }
                        }
                    }
                }]
            },
            plotOptions: {},
            yAxis: {}
        };
    }
}
