import { AppInsights } from 'applicationinsights-js';
import { LogManager } from 'aurelia-framework';
import { AppSettings } from 'config/appsettings';

export const configureApplicationInsights = () => {
    if (AppSettings.appInsightsLogLevel != LogManager.logLevel.none) {
        let appInsightsKey: string = AppSettings.applicationInsightsKey;
        AppInsights.downloadAndSetup({
            instrumentationKey: appInsightsKey
        });
    }
};
