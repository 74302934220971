import $ from 'jquery';

export type NobilModel = {
    Provider: string;
    Rights: string;
    apiver: string;
    chargerstations: NobilChargerStation[];
};

export type NobilChargerStation = {
    csmd: NobilCsmd;
    attr: {
        st: Record<number, NobilAttr>;
        conn: Record<number, NobilAttrs>;
    };
};

export type NobilAttrs = Record<number, NobilAttr>;

export enum NobilAttrType {
    ChargeMode = '20',
    ConnectorType = '4',
    EnergyCarrier = '26',
    VehicleType = '17',
}

export type NobilAttr = {
    attrtypeid: string;
    attrname: string;
    attrvalid: string;
    trans: string;
    attrval: string;
};

export type NobilCsmd = {
    id: number;
    name: string;
    ocpidb_mapping_stasjon_id: string;
    Street: string;
    House_number: string;
    Zipcode: string;
    City: string;
    Municipality_ID: string;
    Municipality: string;
    County_ID: string;
    County: string;
    Description_of_location: null | string;
    Owned_by: string;
    Operator: string;
    Number_charging_points: number;
    Position: string;
    Image: string;
    Available_charging_points: number;
    User_comment: null | string;
    Contact_info: null | string;
    Created: string;
    Updated: string;
    Station_status: number;
    Land_code: string;
    International_id: string;
};

export function loadNobilData(bounds: google.maps.LatLngBounds, callback: (data: any) => void) {
    return $.ajax({
        url: 'https://nobil.no/api/server/search.php',
        data: {
            apikey: '7a2e7a84ce9919fdfd661568be5cfdbd',
            apiversion: '3',
            action: 'search',
            type: 'rectangle',
            northeast: `(${bounds.getNorthEast().lat()}, ${bounds
                .getNorthEast()
                .lng()})`,
            southwest: `(${bounds.getSouthWest().lat()}, ${bounds
                .getSouthWest()
                .lng()})`,
            existingids: '',
        },
        success: (data) => {
            if ('error' in data) {
                return;
            }
            callback(data);
        },
        error: () => { },
        dataType: 'jsonp',
    });
}
