export class VdChartsLegend {
    public title: string;
    public color: string;
    public value: number;
}

export class VdChartsLegendSet {
    public legends: Array<VdChartsLegend> = new Array<VdChartsLegend>();

    public positionTop: boolean = true;
    public autoSortLegends: boolean = true;

    public clearLegends() {
        while (this.legends.length) {
            this.legends.pop();
        }
    }

    public addLegend(title: string, color: string, value = null) {
        var legend = new VdChartsLegend();
        legend.title = title;
        legend.color = color;
        legend.value = value;

        this.legends.push(legend);

        if (!this.autoSortLegends) { return; }

        this.legends = this.legends.sort((x1, x2) => {

            if (x1.value === null || x2.value === null) {
                return 0;
            }

            return x2.value - x1.value;
        });
    }
}